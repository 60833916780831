import { getDiscountAmount, getRealURL, priceToNumber } from '@/lib/utils';
import { AFFILIATION_NAME_OPERATOR, getProductCategoriesTree } from './helpers';

import type { TypeGTMProduct } from '@/types';

const router = () => new URLSearchParams(window?.location?.search);

export const normalizeItem: any = (
  data: any,
  id?: string,
  name?: string,
  index?: number,
): any => {
  const regularPrice = data.regularPrice;
  const salePrice = data.salePrice;
  let sku = data.sku;
  let seller = AFFILIATION_NAME_OPERATOR;

  if (data.isMirakl) {
    sku = data?.variations?.edges[0]?.node?.sku ?? null;

    // TODO: Cuando se implemente el buy box y haya distintos sellers para un mismo producto esto no es correcto
    seller = data?.variations?.edges[0]?.node?.sellerInfo?.name ?? null;
  }

  const discount = data.onSale
    ? Number.parseFloat(
        getDiscountAmount(
          priceToNumber(regularPrice),
          priceToNumber(salePrice),
        ).toFixed(2),
      )
    : null;
  const marca = data?.productMarcaForSeo ?? null;
  const url = data?.uri
    ? getRealURL(data.uri)
    : data?.link
      ? typeof data?.link === 'string'
        ? getRealURL(data.link)
        : null
      : null;

  let itemToAdd: TypeGTMProduct = {
    item_id: sku,
    item_name: data.name,
    // TODO: cuando se implmente el buy box esto no servirá porque puede haber varios sellers
    affiliation: seller,
    coupon: null,
    currency: 'EUR',
    discount,
    index: index ?? null,
    item_brand: marca?.at(0)?.slug,
    item_variant: null,
    price: priceToNumber(regularPrice),
    quantity: 1,
    item_list_id:
      id ?? (router().get('itemListId') as string | undefined) ?? null,
    item_list_name:
      name ?? (router().get('itemListName') as string | undefined) ?? null,
    image: data.image?.sourceUrl ?? null,
    url,
  };

  if (data.productCategoryForSeo) {
    const categories = getProductCategoriesTree(data);

    itemToAdd = {
      ...itemToAdd,
      ...categories,
    };
  }

  return itemToAdd;
};
